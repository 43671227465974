.form
  input
    @apply h-12

  input,
  textarea
    @apply relative
    @apply block
    @apply w-full
    @apply px-3
    @apply bg-primary-400
    @apply border
    @apply border-primary-500
    @apply text-primary-900
    @apply placeholder-primary-900
    @apply transition-colors
    @apply duration-500
    @apply ease-smooth

    &:focus
      @apply text-secondary-500
      @apply bg-primary-100
      @apply border-primary-100
      @apply ring-primary-100
      @apply outline-none

    &:disabled
      @apply border-grey-100
      @apply bg-grey-100
      @apply placeholder-grey-300
      @apply text-grey-300
