////////////////////
// WYSIWYG DEFAULT
////////////////////////////////////////////////////////////////////////////////
.wysiwyg-wrapper
  h2
    @apply text-secondary-500
    @apply font-light
    @apply text-36
    @apply leading-none
    @apply pb-4

    @screen lg
      @apply text-44

  h3
    @apply text-secondary-500
    @apply font-light
    @apply text-28
    @apply leading-none
    @apply pb-4

    @screen lg
      @apply text-36

  h4
    @apply text-secondary-500
    @apply font-light
    @apply text-24
    @apply leading-none
    @apply pb-4

  h5
    @apply text-secondary-500
    @apply font-light
    @apply text-20
    @apply leading-none
    @apply pb-4

  ul
    @include list($bullet-color: "bg-current")
    @apply pb-4

  // CONTENT
  // ---------------------------------------------------------
  p
    @apply pb-4

  strong
    @apply font-bold

  a
    @apply underline

    &:hover
      @apply no-underline

  // IMAGE
  // ---------------------------------------------------------
  img
    @apply h-auto
    @apply max-w-full
    @apply inline-flex
    @apply justify-center
    @apply pb-4

  // CITATION
  // ---------------------------------------------------------
  blockquote
    @apply relative
    @apply w-full
    @apply bg-primary-500
    @apply font-sans
    @apply text-lg
    @apply rounded-md
    @apply text-center
    @apply p-12
    @apply mb-4

    @screen lg
      @apply px-20
      @apply py-8
      @apply text-xl
