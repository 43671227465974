////////////////////
// ANIMATION
////////////////////////////////////////////////////////////////////////////////


// ANIM SOLO
// ---------------------------------------------------------
.anim-fade
  @apply opacity-0
  @apply transition-opacity
  @apply duration-smooth
  @apply ease-smooth

.anim-up
  @apply transition-transform
  @apply duration-smooth
  @apply ease-smooth

.anim-fade-up
  @apply opacity-0
  transform: translate3d(0,30%,0)
  @apply transition-animation
  @apply duration-smooth
  @apply ease-smooth

.anim-height-up
  @apply h-full
  @apply transition-all
  @apply duration-500
  @apply ease-smooth
  animation-delay: 600ms

.anim-svg
  stroke-dasharray: 2000
  stroke-dashoffset: 2000
  animation: circle 5s $transition-timing-smooth forwards
  animation-delay: 600ms

@keyframes circle
  to
    stroke-dashoffset: 0

.anim-arrow
  animation: arrow 700ms $transition-timing-smooth infinite
  animation-timing-function: ease-in-out
  animation-delay: 600ms

@keyframes arrow
  from
    transform: translateY(-4px)

  50%
    transform: translateY(4px)

  to
    transform: translateY(-4px)

////////////////////
// ANIMATION IN
////////////////////////////////////////////////////////////////////////////////
.in,
.active
  &.anim-fade,
  .anim-fade
    @apply opacity-100

  &.anim-up,
  .anim-up
    transform: translate3d(0,0,0)

  &.anim-fade-up,
  .anim-fade-up
    @apply opacity-100
    transform: translate3d(0,0,0)

  &.anim-height-up,
  .anim-height-up
    @apply h-0

// CASCAD
// ---------------------------------------------------------
.anim--cascad
  .anim-fade-up,
  .anim-height-up,
  .anim-fade
    +transitionDelayUp(150ms, 180ms, 1, 20)
