.btn
  @apply inline-flex
  @apply items-center
  @apply rounded-full
  @apply transition-colors
  @apply duration-500
  @apply ease-smooth
  @apply font-light
  @apply cursor-pointer
  @apply text-base

  &:focus
    @apply outline-none
    @apply ring-0

.grecaptcha-badge
  @apply hidden #{!important}

.contact-btn.active
  @apply bg-primary-500
  @apply text-white
