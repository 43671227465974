// .dropdown
//   &__content
//     transform: translate3d(0,95%,0)

//   &.active
//     .dropdown__content
//       @apply translate-y-full
//       @apply opacity-100
//       @apply pointer-events-auto

//     .dropdown__icon
//       @apply rotate-180

.collapse
  &__btn
    &::after
      content: '\f078'
      @apply font-icon
      @apply text-xl
      @apply ml-3

  &__content
    @apply overflow-hidden
    max-height: 0px
    transition: max-height 400ms ease-in-out

  &__input:checked ~ .collapse__btn
    &::after
      content: '\f077'

  &__input:checked ~ .collapse__content
    @apply overflow-auto
    max-height: 400px
    transition: max-height 400ms ease-in-out
