.block-heading
  &--primary
    @apply bg-primary-500
    @apply text-white

    svg
      @apply stroke-white
      @apply opacity-20

  &--dark
    @apply bg-secondary-500
    @apply text-white

    .block-heading__title
      @apply text-primary-500

    .block-heading__subtitle
      @apply text-white

    .block-heading__link
      @apply text-primary-500

    svg
      @apply stroke-secondary-400

  &--light
    @apply bg-white
    @apply text-grey-500

    .block-heading__title
      @apply text-secondary-500

    .block-heading__subtitle
      @apply text-primary-500

    svg
      @apply stroke-grey-100
