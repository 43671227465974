.block-paragraph
  &--primary
    @apply bg-primary-500
    @apply text-white

    .block-paragraph__title
      @apply text-white

    .block-paragraph__link
      @apply text-white

  &--dark
    @apply bg-secondary-500
    @apply text-white

    .block-paragraph__title
      @apply text-primary-500

    .block-paragraph__link
      @apply text-primary-500

  &--light
    @apply bg-white
    @apply text-grey-500

    .block-paragraph__title
      @apply text-secondary-500

    .block-paragraph__link
      @apply text-primary-500
