.block-text-image
  &--left
    .block-text-image__text
      @apply order-last

    .block-text-image__image
      @apply order-first

  &--right
    .block-text-image__text
      @apply order-last

      @screen lg
        @apply order-first

    .block-text-image__image
      @apply order-first

      @screen lg
        @apply order-last

  &--primary
    @apply bg-primary-500
    @apply text-white

  &--dark
    @apply bg-secondary-500
    @apply text-white

    .block-text-image__title
      @apply text-primary-500

    .block-text-image__link
      @apply text-primary-500

  &--light
    @apply bg-white
    @apply text-grey-500

    .block-text-image__title
      @apply text-secondary-500

    .block-text-image__subtitle
      @apply text-primary-500
