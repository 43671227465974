.bloc-quote
  &--primary
    @apply bg-primary-500
    @apply text-grey-500

    path
      @apply text-white

    a
      @apply bg-white
      @apply text-grey-500

      &:hover
        @apply bg-grey-500
        @apply text-white

  &--grey
    @apply bg-grey-500
    @apply text-white

    path
      @apply text-primary-500

  &--light
    @apply bg-white

    path
      @apply text-primary-500
