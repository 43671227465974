// Retracted style on scroll
.header-is-scrolling
  .header-desktop
    @apply h-16
    @apply shadow-lg
    @apply bg-white

    &__logo
      @apply text-grey-500

    &__link
      @apply text-grey-500

      &:hover
        @apply text-primary-600

    .dropdown__button
      @apply text-grey-500

  .header-mobile
    @apply h-16
    @apply shadow-lg
    @apply bg-white

    &__logo
      @apply text-grey-500

    &__burger
      @apply text-primary-500

// Submenu on hover
.submenu
  $current: &
  &:hover
    .header-desktop__link
      @apply text-primary-500

    #{$current}__overlay
      @apply opacity-100
      @apply visible
      @apply translate-y-full
      @apply pointer-events-auto

// Active state
.header-desktop__link.active
  @apply text-primary-500

  div
    @apply opacity-100

.header-mobile__link.active
  @apply text-primary-500
