.menu-translation
  &__wrapper
    @apply opacity-0
    @apply pointer-events-none

  &.active
    .menu-translation__wrapper
      @apply translate-y-0
      @apply opacity-100
      @apply pointer-events-auto
