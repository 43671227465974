@mixin arrowSlider($id-icon: '\f053', $background: 'bg-transparent', $background-hover: 'bg-primary-500', $text-color: 'text-primary-500', $text-color-hover: 'text-white', $border-color: 'border-primary-500')
  @apply #{$background}
  @apply #{$text-color}
  @apply border
  @apply #{$border-color}
  @apply shadow
  @apply rounded-full
  @apply flex
  @apply items-center
  @apply justify-center
  @apply h-8
  @apply w-8
  @apply transition-colors
  @apply ease-in
  @apply duration-300

  &::after
    @apply #{$text-color} #{!important}

  &:hover
    @apply #{$background-hover} #{!important}

    &::after
      @apply #{$text-color-hover} #{!important}

  &::after
    content: $id-icon
    @apply text-xs
    @apply text-gray-600
    @apply font-icon
    @apply font-light
    @apply leading-none
    @apply transition-colors
    @apply ease-in
    @apply duration-300

@mixin bullet()
  @apply bg-transparent #{!important}
  @apply h-4 #{!important}
  @apply w-4 #{!important}
  @apply opacity-100 #{!important}
  @apply transform #{!important}
  @apply scale-100 #{!important}
  @apply border
  @apply border-primary-500

  &-active
     @apply bg-primary-500 #{!important}

.slider-navigation-bottom
  .swiper-wrapper
    @apply relative
    @apply z-10
    @apply pb-8

  .swiper-pagination-bullet
    @include bullet()

  .swiper-button-prev
    @apply top-auto
    @apply bottom-2
    @apply left-auto
    @apply right-10
    @include arrowSlider($id-icon: '\f053')

  .swiper-button-next
    @apply top-auto
    @apply bottom-2
    @apply right-0
    @include arrowSlider($id-icon: '\f054')

.slider-navigation-center
  .swiper-pagination-bullet
    @include bullet()

  .swiper-button-prev
    @include arrowSlider($id-icon: '\f053', $background-hover: 'bg-grey-500', $text-color: 'text-grey-200', $border-color: 'border-grey-200', $text-color-hover: 'text-white')

  .swiper-button-next
    @include arrowSlider($id-icon: '\f054', $background-hover: 'bg-grey-500', $text-color: 'text-grey-200', $border-color: 'border-grey-200', $text-color-hover: 'text-white')

.multi-image
  .swiper-wrapper
    @apply relative
    @apply z-10

  .swiper-pagination
    @apply absolute
    @apply left-0
    @apply right-0
    @apply mx-auto
    @apply bottom-4
    @apply z-20

  .swiper-pagination-bullet
    @include bullet()

  .swiper-button-prev
    @include arrowSlider($id-icon: '\f053')
    @apply left-6
    @apply h-13
    @apply w-13

    @screen xl
      @apply top-auto
      @apply bottom-8

    &::after
      @apply text-20

  .swiper-button-next
    @include arrowSlider($id-icon: '\f054')
    @apply right-6
    @apply h-13
    @apply w-13

    @screen xl
      @apply top-auto
      @apply bottom-8

    &::after
      @apply text-20

// On compense le header et le footer en fix par dessus les slider fullpage
#fullpage.padding-menu .section
  padding-top: 72px

  @screen xl
    padding-top: 60px
    padding-bottom: 60px

#fp-nav
  @apply hidden
  @apply bg-white
  @apply border
  @apply border-gray-200
  @apply rounded-full

  @screen lg
    @apply block

  li a span
    @apply bg-primary-500 #{!important}
    @apply opacity-80 #{!important}

  li a.active span
    @apply opacity-100 #{!important}
