.block-catchphrase
  &--primary
    @apply bg-primary-500
    @apply text-white

    .block-catchphrase__title
      @apply text-white

    .block-catchphrase__author
      @apply text-white

    .block-catchphrase__link
      @apply text-white

  &--dark
    @apply bg-secondary-500
    @apply text-white

    .block-catchphrase__title
      @apply text-white

    .block-catchphrase__author
      @apply text-primary-500

    .block-catchphrase__link
      @apply text-primary-500

  &--light
    @apply bg-white
    @apply text-grey-500

    .block-catchphrase__title
      @apply text-secondary-500

    .block-catchphrase__author
      @apply text-primary-500

    .block-catchphrase__link
      @apply text-primary-500
