////////////////////
// LAYOUT
////////////////////////////////////////////////////////////////////////////////

// LAYOUT BASE
// ---------------------------------------------------------
body, html
  @apply w-full

body
  @apply bg-white
  @apply text-grey-500
  @apply text-base
  @apply font-sans
  @apply font-light
  @apply antialiased
  @apply subpixel-antialiased

.main-wrapper
  @apply min-h-screen
  @apply flex
  @apply flex-col

  &__content
    @apply flex-grow

////////////////////
// LINKS
////////////////////////////////////////////////////////////////////////////////
a:hover, a:focus
  @apply no-underline
  @apply outline-none
