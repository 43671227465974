$path: '../fonts/barlow/'

@mixin declare-font-face($fontFileName, $fontFamilyName, $fontWeight: normal, $fontStyle: normal, $SVGName: '')
  @font-face
    font-family: $fontFamilyName
    font-weight: $fontWeight
    font-style: $fontStyle
    font-display: swap

    src: url('#{$path}#{$fontFileName}.woff2') format('woff2'), url('#{$path}#{$fontFileName}.woff') format('woff')

@include declare-font-face('Barlow-Black', 'Barlow', 900, normal)
@include declare-font-face('Barlow-Bold', 'Barlow', 700, normal)
@include declare-font-face('Barlow-Medium', 'Barlow', 500, normal)
@include declare-font-face('Barlow-Regular', 'Barlow', 400, normal)
@include declare-font-face('Barlow-Light', 'Barlow', 300, normal)

