.bloc-redirection
  &--primary
    @apply bg-primary-500
    @apply text-white

    .bloc-redirection__title
      @apply text-white

    .bloc-redirection__link
      @apply text-white

    svg
      @apply stroke-white
      @apply opacity-20

  &--dark
    @apply bg-secondary-500
    @apply text-white

    .bloc-redirection__title
      @apply text-primary-500

    .bloc-redirection__link
      @apply text-primary-500

    svg
      @apply stroke-secondary-400

  &--light
    @apply bg-white
    @apply text-grey-500

    .bloc-redirection__title
      @apply text-secondary-500

    .bloc-redirection__link
      @apply text-primary-500

    svg
      @apply stroke-grey-100
