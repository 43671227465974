.privacypolicies-com---palette-light.privacypolicies-com---nb
  background-color: rgba(0, 0, 0, 0.75) !important
  border-radius: 0.25rem

.privacypolicies-com---nb
  .cc-nb-main-container
    @apply bg-white
    @apply h-full
    @apply absolute
    transform: translate(-50%,-50%)
    top: 50%
    left: 50%
    width: 100%

    @screen lg
      @apply rounded-md
      @apply h-auto
      max-width: 55% !important

    .cc-nb-buttons-container
      margin-top: 4rem
      @apply grid
      @apply grid-cols-2
      @apply gap-4

      @screen lg
        @apply flex
        @apply flex-row
        margin: 0 auto 0 !important
        margin-top: 0

      .cc-nb-okagree
        @apply bg-primary-500
        @apply mx-auto #{!important}
        order: 0

        &:hover
          @apply bg-primary-600

        &:focus
          @apply ring-primary-500
          @apply shadow-lg

        @screen lg
          @apply m-0 #{!important}
          @apply mr-4 #{!important}

      .cc-nb-reject
        @apply text-black #{!important}
        background-color: transparent !important
        text-decoration: underline !important
        padding: 0 !important
        @apply col-span-2
        @apply text-center
        @apply mx-auto #{!important}
        order: 3

        @screen lg
          @apply mr-0 #{!important}

        &:focus
          @apply ring-0
          @apply ring-offset-0

      .cc-nb-changep
        border: solid black 1px
        @apply text-black #{!important}
        @apply bg-transparent #{!important}
        @apply mx-auto #{!important}
        order: 2
        @apply ml-0  #{!important}

        &:hover
          @apply border-primary-500
          @apply text-primary-500 #{!important}

        &:focus
          @apply ring-primary-500


.privacypolicies-com---reset
  button
    @apply block #{!important}
    @apply items-center #{!important}
    @apply rounded-full #{!important}
    @apply transition-colors #{!important}
    @apply duration-500 #{!important}
    @apply ease-smooth #{!important}
    @apply font-medium #{!important}
    @apply cursor-pointer #{!important}
    @apply text-base
    @apply px-6 #{!important}
    @apply py-3 #{!important}
    @apply text-base #{!important}
    @apply text-white #{!important}
    @apply w-auto #{!important}
    @apply mx-auto #{!important}

    @screen lg
      @apply mx-0
      @apply inline-flex

    &:focus
      @apply outline-none
      @apply ring-2
      @apply ring-offset-2

.privacypolicies-com---palette-light
  .cc-cp-foot-save
    @apply bg-primary-500 #{!important}

    &:hover
      @apply bg-primary-600

    &:focus
      @apply ring-primary-500
      @apply shadow-lg

    @screen lg
      @apply m-0
