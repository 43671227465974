.block-activities
  &--primary
    @apply bg-primary-500
    @apply text-white

    .slider-navigation-bottom
      .swiper-button-next
        @apply border-white #{!important}

        &::after
          @apply text-white #{!important}

      .swiper-button-prev
        @apply border-white #{!important}

        &::after
          @apply text-white #{!important}

  &--dark
    @apply bg-secondary-500
    @apply text-white
