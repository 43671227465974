.modal__container
  @apply not-sr-only
  @apply transform
  @apply scale-100
  @apply transition-opacity
  @apply transition-transform
  @apply ease-smooth
  @apply duration-200

  &.disable
    @apply sr-only
    @apply duration-500
    @apply scale-90
