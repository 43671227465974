.block-kpi
  &--primary
    @apply bg-primary-500
    @apply text-white

    .block-kpi__title
      @apply text-white

    .block-kpi__subtitle
      @apply text-white

    .block-kpi__card
      @apply bg-white
      @apply text-primary-500

  &--dark
    @apply bg-secondary-500
    @apply text-white

    .block-kpi__title
      @apply text-primary-500

    .block-kpi__subtitle
      @apply text-white

    .block-kpi__card
      @apply bg-primary-500
      @apply text-secondary-500

  &--light
    @apply bg-white
    @apply text-grey-500

    .block-kpi__title
      @apply text-secondary-500

    .block-kpi__subtitle
      @apply text-secondary-500

    .block-kpi__card
      @apply bg-secondary-500
      @apply text-primary-500

