////////////////////
// SASS VARIABLES
////////////////////////////////////////////////////////////////////////////////

// COLORS
// ---------------------------------------------------------
$primary-50: #FEEEBD
$primary-100: #FEE9A9
$primary-200: #FDDF81
$primary-300: #FDD559
$primary-400: #FCCA30
$primary-500: #FCC008
$primary-600: #EDB300
$primary-700: #D8A300
$primary-800: #C49300
$primary-900: #B78A00

$secondary-50: #209FE6
$secondary-100: #1994D8
$secondary-200: #157BB4
$secondary-300: #10628F
$secondary-400: #0C496B
$secondary-500: #083046
$secondary-600: #062232
$secondary-700: #062232
$secondary-800: #011825

$grey-50: #F6F5F1
$grey-100: #F1F0EC
$grey-200: #DEDEDE
$grey-300: #C0C0C0
$grey-400: #ABABAB
$grey-500: #808080
$grey-600: #484F55
$grey-700: #343A40
$grey-800: #2B2F34
$grey-900: #202225

/////// USEFULL COLORS
$cyan: #62c5da
$green: #36d2a1
$red: #ff6363
$yellow: #ffaf57

///// SHARED COLORS
$brand-info: $cyan
$brand-success: $green
$brand-danger: $red
$brand-warning: $yellow

// TRANSITIONS
// ---------------------------------------------------------
$transition-duration-default: 350ms
$transition-timing-default: ease
$transition-default: $transition-duration-default $transition-timing-default
$transition-duration-smooth: 600ms
$transition-timing-smooth: cubic-bezier(.4, 0,.2,1)
$transition-smooth: var(--transition-duration-smooth) var(--transition-timing-smooth)


// TYPOGRAPHY
// ---------------------------------------------------------
$text-font-size: 1rem //-> 16px general font-size apply on body


////////////////////
// CSS CUSTOM PROPERTIES
////////////////////////////////////////////////////////////////////////////////

// https://sass-lang.com/documentation/breaking-changes/css-vars
\:root
  ///// COLORS
  --primary-50: #{$primary-50}
  --primary-100: #{$primary-100}
  --primary-200: #{$primary-200}
  --primary-300: #{$primary-300}
  --primary-400: #{$primary-400}
  --primary-500: #{$primary-500}
  --primary-600: #{$primary-600}
  --primary-700: #{$primary-700}
  --primary-800: #{$primary-800}
  --primary-900: #{$primary-900}
  --secondary-50: #{$secondary-50}
  --secondary-100: #{$secondary-100}
  --secondary-200: #{$secondary-200}
  --secondary-300: #{$secondary-300}
  --secondary-400: #{$secondary-400}
  --secondary-500: #{$secondary-500}
  --secondary-600: #{$secondary-600}
  --secondary-700: #{$secondary-700}
  --secondary-800: #{$secondary-800}
  --grey-50: #{$grey-50}
  --grey-100: #{$grey-100}
  --grey-500: #{$grey-200}
  --grey-300: #{$grey-300}
  --grey-400: #{$grey-400}
  --grey-500: #{$grey-500}
  --grey-600: #{$grey-600}
  --grey-700: #{$grey-700}
  --grey-800: #{$grey-800}
  --grey-900: #{$grey-900}

  ///// FONTS
  --font-family-sans: 'Barlow', "Helvetica Neue", Helvetica, Arial, sans-serif
  --font-family-icon: 'Font Awesome 6 Pro'

  ///// TYPOGRAPHY
  --text-font-size: #{$text-font-size} // -> general font-size apply on body

  ///// ANIMATIONS
  --transition-duration-default: #{$transition-duration-default}
  --transition-timing-default: #{$transition-timing-default}
  --transition-default: #{$transition-duration-default} #{$transition-timing-default}
  --transition-duration-smooth:  #{$transition-duration-smooth}
  --transition-timing-smooth:  #{$transition-timing-smooth}
  --transition-smooth:  #{$transition-duration-smooth}  #{$transition-timing-smooth}


////////////////////
// OVERRIDE VENDORS
////////////////////////////////////////////////////////////////////////////////
$enable-responsive-font-sizes: true
$lg-path-fonts: 'lightgallery/fonts'
$lg-path-images: 'lightgallery/images'
